import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAuthUser from '../authentification/userService'
import useAlertNotification from '../notification/useAlert'
// import router from '@/router'

export default function useDiffusion() {
  const users = ref([])
  const diffusion = ref([])
  const errors = ref('')
  const loader = ref(false)
  const diffusionProcess = ref(false)
  const { errorToast, successToast } = useAlertNotification()

  const {
    getAuthUser,
    user,
  } = useAuthUser()

  // Liste des offres annulé
  const getUsersByTransporteurSociete = async () => {
    loader.value = true
    await axiosClient.get('/get-all-user-by-societe-and-transporteur').then(response => {
      if (response.data.success === true) {
        loader.value = false
        users.value = response.data.data
      }
    })
  }
  // Réseau de transporteurs
  const listeDiffusion = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/users/${user.value.id}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          diffusion.value = response.data.data.liste_diffusions
        }
      })
    })
  }
  // Liste des offres annulé
  const handlePostDiffusion = async data => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.post(`/create-diffusion-list/${user.value.id}`, data).then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          loader.value = false
          users.value = response.data.data
          listeDiffusion()
        }
      })
    })
  }

  // Supprimer une offre
  const deleteDiffusion = async data => {
    diffusionProcess.value = true
    await getAuthUser().then(() => {
      axiosClient.post(`/delete-diffusion-list/${user.value.id}`, data)
        .then(response => {
          if (response.data.success === true) {
            successToast('Suppression', response.data.message)
            diffusionProcess.value = true
            diffusionProcess.value = false
            listeDiffusion()
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
          } else {
            errorToast('Oups! Erreur', error.response.data.message)
          }
        })
    })
  }
  return {
    getUsersByTransporteurSociete,
    loader,
    errors,
    users,
    diffusion,
    handlePostDiffusion,
    listeDiffusion,
    deleteDiffusion,
    diffusionProcess,

  }
}
